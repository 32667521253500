import { acceptHMRUpdate, defineStore } from 'pinia'
export const useNavStore = defineStore('nav', () => {
  const navData = reactive({
    nav: {}
  })
  const nav = computed(() => navData.nav)
  function setNav (nav) {
    navData.nav = nav
  }
  return {
    nav,
    setNav
  }
})

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot)) }
